export const GET_PROFILES = '/api/profiles'
export const GET_LOG = '/api/profile/getlog'

export const SHORTLIST = '/api/profiles/'
export const REJECT = '/api/profiles/'

export const HIRED = '/api/profiles/'
export const MAKE_LOG = '/api/profile/log'

export const SHOW_PROFILE = '/api/profiles/'

export const URL = 'http://101.53.158.103:3056/resumes/'
export const URL1 = 'http://101.53.158.103:3056/resumes/'
